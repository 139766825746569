import React from "react"
import { graphql } from "gatsby"
import { Row, Col, Hidden, Visible } from 'react-grid-system'

import Layout from "../components/layout"
import Image1 from "../images/chiropractie_onno_drenth.png"
import Image2 from "../images/onno_drenth_objecten.png"
import Image3 from "../images/onno_drenth_behandeling_2.png"



export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds our post data
  const { frontmatter, html } = markdownRemark
  const { order } = frontmatter;

  const Image = (() => {
    switch(order) {
      case 0:
        return Image1
        break;
      case 1:
        return Image2
        break;
      case 2:
      case 3:
        return Image3
        break;
      default:
        return Image1
    }
  })()

  return (
    <Layout>
      <div className="content">
        <Row>
          <Hidden md xs>
            <Col lg={4} offset={{ lg: 1 }}>
              <img className='content-image' src={Image} alt="Onno Drenth Chiropraxie" />
            </Col>
          </Hidden>
          <Visible xs>
            <Col xs={12}>
              <img className='content-image' src={Image} alt="Onno Drenth Chiropraxie" style={{paddingTop: 0}} />
            </Col>
          </Visible>
          <Col lg={4} md={10} offset={{ lg: 1, md: 1 }}>
            <h1>{frontmatter.title}</h1>
            <div
              className="blog-post-content"
              dangerouslySetInnerHTML={{ __html: html }}
            />
          </Col>
          <Visible md>
            <Col md={10} offset={{ md: 1 }}>
              <img className='content-image' src={Image} alt="Onno Drenth Chiropraxie" />
            </Col>
          </Visible>
        </Row>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        order
      }
    }
  }
`