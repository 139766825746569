import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'
import PostLink from "../components/post-link"

import "./navigation.scss"

const Navigation = ({ mobile }) => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
        edges {
          node {
            id
            excerpt(pruneLength: 250)
            frontmatter {
              path
              title
              order
            }
          }
        }
      }
    }
  `)

  const Posts = data.allMarkdownRemark.edges
    .sort((a, b) => a.node.frontmatter.order - b.node.frontmatter.order)
    .map(edge => <PostLink key={edge.node.id} post={edge.node} />)

  if (mobile) {
    return (
      <div className="navigation-mobile">
        <div>
          <div className="navigation-item">
            <Link to={'/'}>
              Home
            </Link>
          </div>
          <div className="navigation-dropdown">
            Chiropraxie
            <div className="navigation-dropdown-menu">
              <ul>
                {Posts}
              </ul>
            </div>
          </div>
          <div className="navigation-item">
            <Link to={'/contact'}>
              Contact
            </Link>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="navigation">
      <div className="navigation-item">
        <Link to={'/'}>
          Home
        </Link>
      </div>
      <div className="navigation-dropdown">
        Chiropraxie <FontAwesomeIcon icon={faCaretDown} />
        <div className="navigation-dropdown-menu">
          <ul>
            {Posts}
          </ul>
        </div>
      </div>
      <div className="navigation-item">
        <Link to={'/contact'}>
          Contact
        </Link>
      </div>
    </div>
  )
}

Navigation.propTypes = {
  mobile: PropTypes.bool,
}

Navigation.defaultProps = {
  mobile: false,
}

export default Navigation