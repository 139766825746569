import React from "react"
import { Row, Col, Hidden, Visible } from 'react-grid-system'

import Brandmerk from "../images/brandmerk.svg"
import BrandmerkKlein from "../images/brandmerk-klein.svg"
import Navigation from "./navigation";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

import "./header.scss"

class Header extends React.Component {
  state = {
    mobileNavigatonOpen: false
  }

  clickHandler = (value) => this.setState({mobileNavigatonOpen: value})

  render() {
    const hamburger = this.state.mobileNavigatonOpen
      ? <FontAwesomeIcon className='hamburger-icon' icon={faTimes} size='2x' onClick={() => this.clickHandler(false)} />
      : <FontAwesomeIcon className='hamburger-icon' icon={faBars} size='2x' onClick={() => this.clickHandler(true)} />

    return (
      <React.Fragment>
        <Hidden xs>
          <div className="header">
              <Row justify="center">
                  <Col lg={2} md={4} offset={{ lg: 1, md: 0 }}>
                    <img src={Brandmerk} alt="Onno Drenth Chiropraxie" />
                  </Col>
                  <Col lg={3} md={5} offset={{ lg: 5, md: 1 }} >
                    <Navigation />
                  </Col>
              </Row>
          </div>
        </Hidden>
        <Visible xs>
          <div className="header-xs">
            <Row>
              <Col xs={7}>
                <img src={BrandmerkKlein} alt="Onno Drenth Chiropraxie" />
              </Col>
              <Col xs={2} offset={{ xs: 3 }}>
                {hamburger}
              </Col>
            </Row>
          </div>
        </Visible>
        {this.state.mobileNavigatonOpen && <Navigation mobile={true} />}
      </React.Fragment>
    )
  }
}

export default Header